import { Injectable, inject } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
  StateOperator,
  compose,
  patch,
  updateItem,
} from '@ngxs/store/operators';
import { SaveMenuState } from 'src/app/core/actions/settings.action';
import {
  MenuState,
  MenuStates,
} from 'src/app/shared/models/account/account-settings.model';
import {
  SetInitialMenuV2State,
  SetMenuV2Options,
  ToggleMenuExpansion,
  ToggleMenuVisilibity,
} from '../actions/nav-menu-v2.actions';
import {
  MenuOption,
  menuOptions,
} from '../components/nav-menu-v2/model/nav-menu-v2.model';
import { AccountPermissionsState } from 'src/app/core/states/account-permissions.state';
import { WebshopState } from 'src/app/core/states/webshop.state';

export interface NavMenuV2StateModel {
  closed: boolean;
  expanded: boolean;
  menuOptions: MenuOption[];
}

@State<NavMenuV2StateModel>({
  name: 'navMenuV2State',
  defaults: {
    closed: false,
    expanded: false,
    menuOptions: menuOptions,
  },
})
@Injectable()
export class NavMenuV2State {
  private store = inject(Store);

  @Selector()
  static closed(state: NavMenuV2StateModel): boolean {
    return state.closed;
  }

  @Selector()
  static expanded(state: NavMenuV2StateModel): boolean {
    return state.expanded;
  }

  @Selector()
  static menuOptions(state: NavMenuV2StateModel): MenuOption[] {
    return state.menuOptions;
  }

  @Action(SetInitialMenuV2State)
  setInitialState(
    ctx: StateContext<NavMenuV2StateModel>,
    action: SetInitialMenuV2State
  ) {
    ctx.setState(
      patch<NavMenuV2StateModel>({
        closed: action.menuState === MenuStates.HIDDEN,
        expanded: action.menuState === MenuStates.EXPANDED,
      })
    );
  }

  @Action(SetMenuV2Options, { cancelUncompleted: true })
  setMenuOptions(ctx: StateContext<NavMenuV2StateModel>) {
    const permissionFeatureKeys = this.store.selectSnapshot(
      AccountPermissionsState.permissionsKeys
    );

    const menus = ctx.getState().menuOptions;

    ctx.setState(
      compose(
        ...menus.map(menu =>
          this._defineMenuOptions(menu, permissionFeatureKeys)
        )
      )
    );
  }

  @Action(ToggleMenuVisilibity, { cancelUncompleted: true })
  toggleMenuVisibility(ctx: StateContext<NavMenuV2StateModel>) {
    let menuState: MenuState;

    if (ctx.getState().closed) {
      menuState = ctx.getState().expanded
        ? MenuStates.COLLAPSED
        : MenuStates.EXPANDED;
    } else {
      menuState = MenuStates.HIDDEN;
    }

    ctx.patchState({
      closed: !ctx.getState().closed,
    });

    return ctx.dispatch(new SaveMenuState(menuState));
  }

  @Action(ToggleMenuExpansion, { cancelUncompleted: true })
  toggleMenuExpansion(ctx: StateContext<NavMenuV2StateModel>) {
    const menuState: MenuState = ctx.getState().expanded
      ? MenuStates.COLLAPSED
      : MenuStates.EXPANDED;

    ctx.patchState({
      expanded: !ctx.getState().expanded,
    });

    return ctx.dispatch(new SaveMenuState(menuState));
  }

  private _defineMenuOptions(
    menu: MenuOption,
    permissionFeatureKeys: string[]
  ): StateOperator<NavMenuV2StateModel> {
    return patch<NavMenuV2StateModel>({
      menuOptions: updateItem(
        option => option.id === menu.id,
        patch<MenuOption>({
          enabled:
            menu.featureKey !== null
              ? this._checkPermission(menu, permissionFeatureKeys)
              : true,
        })
      ),
    });
  }

  private _checkPermission(menu: MenuOption, permissions: string[]): boolean {
    if (!Array.isArray(menu.featureKey)) {
      return (
        permissions.includes(menu.featureKey) &&
        this._checkWebshopPermission(menu.featureKey)
      );
    }

    if (menu.anyOf) {
      return (
        this._checkAnyOfPermissions(menu.featureKey, permissions) ||
        this._checkAnyOfWebshopPermissions(menu.featureKey)
      );
    }

    return (
      this._checkAllOfPermissions(menu.featureKey, permissions) &&
      this._checkAllOfWebshopPermissions(menu.featureKey)
    );
  }

  private _checkAnyOfPermissions(featureKeys: string[], permissions: string[]) {
    return featureKeys.some(key => permissions.includes(key));
  }

  private _checkAllOfPermissions(featureKeys: string[], permissions: string[]) {
    return featureKeys.every(key => permissions.includes(key));
  }

  private _checkWebshopPermission(featureKey: string): boolean {
    return this.store.selectSnapshot(
      WebshopState.hasWebshopPermission(featureKey)
    );
  }

  private _checkAnyOfWebshopPermissions(featureKeys: string[]): boolean {
    return featureKeys.some(key => this._checkWebshopPermission(key));
  }

  private _checkAllOfWebshopPermissions(featureKeys: string[]): boolean {
    return featureKeys.every(key => this._checkWebshopPermission(key));
  }
}
