import { RolesFeatureKeys } from 'src/app/core/constants/roles.constants';

export interface MenuOption {
  id: string;
  featureKey: string | string[];
  /**
   * If true, the feature will be enabled if any of the feature keys are enabled, else all of them must be enabled.
   */
  anyOf?: boolean;
  icon: string;
  name: string;
  route: string;
  intercomTarget: string;
  enabled: boolean;
  primary: boolean;
}

export const menuOptions: MenuOption[] = [
  {
    id: 'dashboard',
    featureKey: null,
    icon: 'home',
    name: $localize`Home`,
    intercomTarget: 'Home',
    route: 'home',
    enabled: true,
    primary: true,
  },
  {
    id: 'analytics',
    featureKey: RolesFeatureKeys.ANALYTICS,
    icon: 'analytics',
    name: $localize`Analytics`,
    intercomTarget: 'Analytics',
    route: 'analytics',
    enabled: false,
    primary: true,
  },
  {
    id: 'purchase',
    featureKey: RolesFeatureKeys.PURCHASE,
    icon: 'shopping-basket',
    name: $localize`Purchase`,
    intercomTarget: 'Purchase',
    route: 'purchase',
    enabled: false,
    primary: true,
  },
  {
    id: 'deliveries',
    featureKey: RolesFeatureKeys.DELIVERIES,
    icon: 'truck',
    name: $localize`Deliveries`,
    intercomTarget: 'Deliveries',
    route: 'deliveries',
    enabled: false,
    primary: true,
  },
  {
    id: 'assembly',
    featureKey: RolesFeatureKeys.ASSEMBLY,
    icon: 'conveyer-belt',
    name: $localize`Assembly`,
    intercomTarget: 'Assembly',
    route: 'assembly',
    enabled: false,
    primary: true,
  },
  {
    id: 'products',
    featureKey: RolesFeatureKeys.PRODUCTS,
    icon: 'box-closed',
    name: $localize`Products`,
    intercomTarget: 'Products',
    route: 'products',
    enabled: false,
    primary: true,
  },
  {
    id: 'suppliers',
    featureKey: RolesFeatureKeys.SUPPLIERS,
    icon: 'store',
    name: $localize`Suppliers`,
    intercomTarget: 'Suppliers',
    route: 'suppliers',
    enabled: false,
    primary: true,
  },
  {
    id: 'promotions',
    featureKey: RolesFeatureKeys.PROMOTIONS,
    icon: 'promotion-tag',
    name: $localize`Promotions`,
    intercomTarget: 'Promotions',
    route: 'promotions',
    enabled: false,
    primary: true,
  },
  {
    id: 'settings',
    featureKey: RolesFeatureKeys.SETTINGS,
    icon: 'settings-cog',
    name: $localize`Settings`,
    intercomTarget: 'Settings',
    route: 'settings',
    enabled: false,
    primary: false,
  },
];
